<template>
  <div class="comments-tab" :class="{ 'comments-tab__no-comment': isNoCommentsViewOpened }">
    <mobile-header>
      <span slot="header-title" class="comments-mobile-header">
        {{ $t('dashboard.medicalData.comments') }}
      </span>
    </mobile-header>

    <div
      v-if="isNoCommentsViewOpened"
      class="comments-empty__container flex--center--center flex-column"
    >
      <div class="icon-empty-comments"></div>
      <span class="comments-empty__text no-search-field">
        {{ $t('dashboard.label.noComments') }}
      </span>
      <button class="new-comment-add-button rounded-button-white" @click="addFirstComment">
        {{ $t('dashboard.label.addComment') }}
      </button>
    </div>

    <div v-else class="comments-container">
      <button
        v-show="patientHasComments"
        class="new-comment-add-button rounded-button-white"
        @click="openNewCommentTextArea"
      >
        {{ $t('dashboard.label.addComment') }}
      </button>

      <div>
        <div v-show="isNewCommentAdding" class="add-comment-container add-comment">
          <i
            class="add-comment__header
          "
          >
            {{ todayDate | formatDate }}
          </i>
          <textarea-autosize
            ref="newCommentTextarea"
            v-model="newCommentText"
            :min-height="36"
            :rows="3"
            class="new-comment-textarea add-comment__textarea rounded-input "
            @blur.native="saveNewComment"
          />
        </div>

        <div v-for="comment in comments" :key="comment.id" class="comment-container">
          <comment
            :comment="comment"
            @open-delete-comment-modal="openDeleteCommentModal"
            @edit-comment="editComment"
          />
        </div>
      </div>
    </div>

    <delete-comment-modal @delete="deleteComment" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import DeleteCommentModal from '@/modules/dashboard/components/patient-card/comments/DeleteCommentModal';
import Comment from '@/modules/dashboard/components/patient-card/comments/Comment';
import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';

import { types as dashTypes } from '@/modules/dashboard/store/types';

const TODAY_DATE = new Date();

export default {
  name: 'CommentsTab',
  components: {
    DeleteCommentModal,
    MobileHeader,
    Comment
  },
  data() {
    return {
      todayDate: TODAY_DATE,
      shouldShowNoComments: true,
      isNewCommentAdding: false,
      newCommentText: ''
    };
  },
  computed: {
    ...mapGetters({
      currentPatient: dashTypes.getters.CURRENT_PATIENT,
      comments: dashTypes.getters.CURRENT_PATIENT_COMMENTS
    }),
    patientHasComments() {
      return !!this.comments.length;
    },
    isNoCommentsViewOpened() {
      return this.shouldShowNoComments && !this.patientHasComments;
    }
  },
  created() {
    this.setShowNoComments(!this.patientHasComments);
  },
  methods: {
    ...mapActions({
      addComment: dashTypes.actions.ADD_PATIENT_COMMENT,
      editPatientComment: dashTypes.actions.EDIT_PATIENT_COMMENT,
      deletePatientComment: dashTypes.actions.DELETE_PATIENT_COMMENT
    }),
    addFirstComment() {
      this.openNewCommentTextArea();
      this.setShowNoComments(false);
    },
    setShowNoComments(value) {
      this.shouldShowNoComments = value;
    },
    closeAddingNewComment() {
      this.isNewCommentAdding = false;
      this.newCommentText = '';
    },
    async openNewCommentTextArea() {
      if (this.isNewCommentAdding) {
        return;
      }

      this.isNewCommentAdding = true;

      await this.$nextTick();

      this.$refs.newCommentTextarea.$el.focus();
    },
    async saveNewComment() {
      if (!this.newCommentText.trim()) {
        this.closeAddingNewComment();
        this.setShowNoComments(!this.patientHasComments);

        return;
      }

      await this.addComment({ patientId: this.currentPatient.id, text: this.newCommentText });

      this.closeAddingNewComment();
    },
    async editComment(id, commentRef) {
      const { val: text } = commentRef;

      await this.editPatientComment({ id, text });
    },
    openDeleteCommentModal(commentId) {
      this.$modal.show('delete-comment-modal', { commentId });
    },
    async deleteComment(id) {
      await this.deletePatientComment(id);

      this.setShowNoComments(!this.patientHasComments);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/inputs';
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';

.comments-empty-container {
  height: 500px;
  width: 100%;
  background-color: $background-darker;
}

.comments {
  &__text {
    margin: 50px 0 20px;

    font-size: 15px;
    color: $grey-color;
  }
}

.add-comment {
  width: 585px;
  position: relative;

  &__header {
    display: block;
    margin: 30px 0 15px 15px;
    font-size: 12px;
    color: $date-grey-color;
  }

  &__textarea {
    width: 560px;
    margin-left: -10px;
  }
}

.rounded-input {
  margin-bottom: 0;
  padding: 9px 25px;

  font-size: 15px;
  line-height: 17px;
}

.fa-trash-alt {
  display: none;

  position: absolute;

  top: 43px;
  left: 510px;

  color: $icon-grey-color;
}

.comments-dropdown {
  &__options-dropdown {
    &--opened {
      display: block;
      top: 25px;
    }
  }
}

@media (max-width: 767px) {
  .new-comment-add-button {
    height: auto;

    margin-top: 20px;
    padding: 7px 18px;

    font-size: 16px;
    line-height: 19px;
  }

  .comments-empty-container {
    height: 100vh;
  }

  .comments {
    &-mobile-header {
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;

      color: $main-title-color;
    }

    &-container {
      margin-top: 46px;
      padding: 0 15px;
    }

    &-tab {
      margin-bottom: 30px;

      &__no-comment,
      .mobile-header {
        margin-bottom: 0;
      }
    }
  }

  .add-comment {
    width: 100%;

    &__header {
      display: flex;
      align-items: center;
      min-height: 25px;
      margin: 15px 0 10px;
      font-size: 12px;
      color: $date-grey-color;
    }

    &__textarea {
      width: 290px;
      margin-left: 0;

      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
