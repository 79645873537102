<template>
  <modal
    name="delete-comment-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <p class="title">{{ $t('dashboard.medicalData.deleteCommentQuestion') }}</p>
        <div class="buttons-group">
          <a class="modal-button cancel-button" @click="hideModal">
            {{ $t('dashboard.action.cancel') }}
          </a>
          <button class="modal-button rounded-button-white" @click="onDelete">
            {{ $t('dashboard.action.yesDelete') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'DeleteCommentModal',
  data() {
    return {
      commentId: null
    };
  },
  methods: {
    beforeOpen({ params: { commentId } }) {
      this.commentId = commentId;
    },
    hideModal() {
      this.$modal.hide('delete-comment-modal');
    },
    onDelete() {
      this.$emit('delete', this.commentId);
      this.hideModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons';

.modal-window-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
}

.title {
  margin-top: 40px;
  margin-bottom: 40px;

  font-size: 13px;
  line-height: 19px;

  color: #324752;
}

.cancel-button {
  font-size: 15px;
  line-height: 17px;

  text-decoration: none;

  color: #8da4b0;
}

.rounded-button-white {
  font-size: 15px;
  line-height: 17px;
}

.buttons-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 767px) {
  .modal-button {
    font-size: 13px;
  }
}
</style>
