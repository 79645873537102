<template>
  <div class="comment-container">
    <div class="comment-header flex--space-between--center">
      <div class="comment-date">
        <i>
          {{ comment.creationDate | formatDate }}
          <span v-if="comment.edited">({{ $t('dashboard.label.edited') }})</span>
        </i>
      </div>

      <div ref="dropdown" class="comments-dropdown onlyXS">
        <div
          class="vertical-dots dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
          @click="toggleDropdown"
        />

        <div
          class="comments-dropdown main-dropdown dropdown-menu dropdown-menu-right"
          :class="{ 'comments-dropdown__options-dropdown--opened': isDropdownOpened }"
          @click="$emit('open-delete-comment-modal', comment.id)"
        >
          <div class="dropdown-item main-dropdown__option flex--align-center">
            <span class="far fa-trash-alt" />
            {{ $t('dashboard.medicalData.deleteComment') }}
          </div>
        </div>
      </div>
    </div>

    <textarea-autosize
      :ref="`comment${comment.id}`"
      :value="comment.text"
      :min-height="36"
      :rows="1"
      class="comment-text rounded-input"
      @blur.native="$emit('edit-comment', comment.id, $refs[`comment${comment.id}`])"
    />
    <span class="comment-pencil-icon icon-pencil custom-icon"></span>
    <span
      class="far fa-trash-alt pointer"
      @click="$emit('open-delete-comment-modal', comment.id)"
    />
  </div>
</template>

<script>
import { legacyDropdownMixin } from '@/modules/dashboard/mixins/dropdown/legacyDropdownMixin';

export default {
  name: 'Comment',
  mixins: [legacyDropdownMixin],
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newCommentText: ''
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/dropdowns.css';
@import '../../../../../assets/css/common/inputs';
@import '../../../../../assets/scss/helpers/colors';

.comment-container {
  width: 585px;
  position: relative;

  &:hover {
    .comment {
      &-text {
        background-color: $white;
        cursor: pointer;
      }

      &-pencil-icon {
        display: block;
      }
    }

    .fa-trash-alt {
      display: block;
    }
  }
}

.comment {
  &-header {
    margin: 30px 0 15px 15px;
  }

  &-date {
    display: block;

    font-size: 12px;
    color: $date-grey-color;
  }

  &-text {
    width: 500px;

    padding: 10px 45px 10px 15px;

    font-size: 15px;

    background-color: $background;
    color: $main-title-color;

    border: 1px solid $background;
    border-radius: 5px;

    white-space: pre-wrap;
    word-wrap: break-word;

    box-sizing: border-box;

    &:focus {
      background-color: $white;
      border: 1px solid $light-blue;

      & ~ .comment-pencil-icon {
        display: none;
      }

      & ~ .fa-trash-alt {
        display: block;
      }
    }
  }

  &-pencil-icon {
    display: none;

    position: absolute;

    top: 43px;
    left: 479px;
  }
}

.fa-trash-alt {
  display: none;

  position: absolute;

  top: 43px;
  left: 510px;

  color: $icon-grey-color;
}

.comments-dropdown {
  &__options-dropdown {
    &--opened {
      display: block;
      top: 25px;
    }
  }
}

@media (max-width: 767px) {
  .comment-container {
    max-width: 100vw;
    width: 100%;
    position: relative;

    &:hover {
      .comment {
        &-pencil-icon {
          display: none;
        }
      }

      .fa-trash-alt {
        display: none;
      }

      .dropdown-item {
        font-size: 13px;

        .fa-trash-alt {
          display: block;

          position: static;

          margin-right: 8px;
        }
      }
    }
  }

  .comment {
    position: relative;

    &-header {
      display: flex;
      align-items: center;
      min-height: 25px;
      margin: 15px 0 10px;
    }

    &-text {
      width: 100%;
      background-color: $white;

      font-size: 16px;
      line-height: 19px;

      &:focus {
        border: 1px solid $light-blue;

        & ~ .comment-pencil-icon,
        & ~ .fa-trash-alt {
          display: none;
        }
      }
    }
  }
}
</style>
