export const legacyDropdownMixin = {
  data() {
    return {
      toggleEvent: null,
      dropdownMixinIsActive: false
    };
  },
  computed: {
    isDropdownOpened() {
      return this.dropdownMixinIsActive;
    }
  },
  mounted() {
    document.addEventListener('click', this.dropdownMixinHandleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.dropdownMixinHandleDocumentClick);
  },
  methods: {
    dropdownMixinHandleDocumentClick(event) {
      if (this.toggleEvent !== event) {
        this.dropdownMixinIsActive = false;
      }
    },
    toggleDropdown(event) {
      this.toggleEvent = event;
      this.dropdownMixinIsActive = !this.dropdownMixinIsActive;
    }
  }
};
